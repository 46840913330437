.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .tabs button {
    background-color: #F0F0F0;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  .tabs button.active {
    background-color: #007BFF;
    color: white;
  }
  .tabs button:hover {
    background-color: #0056B3;
    color: white;
  }
  /* General grid layout */
  .video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
  }
  .video-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .video-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  .video-player {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
  }